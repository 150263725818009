
import { InputHelperFactory } from '../../../form/inputs/factories/input-helper.factory'
import { LocalizationPath } from '../../../../../../../core/src/models/common/localization/constants/localization-path'
import { LocalizationData } from '../../../../../../../core/src/models/common/localization/localization-data'
import { LocalizationService } from '../../../../../../core/src/modules/language/localization-service'
import { IconSize } from '../../c-icon/enum/icon-size.enum'
import { CIconFactory } from '../../c-icon/factory/c-icon.factory'
import { IFilterMainButton } from '../interface/i-filter-main-button'

/**
 * Creates buttons for the the c-filter-search, c-filer-multi, etc.
 * @todo Create builder for the buttons / css classes /etc
 */
export class FilterMainButton {
  /**
   * Generic button for FilterMainButton class.
   */
  static Button(props: IFilterMainButton): IFilterMainButton {
    return {
      ...props
    }
  }

  /**
   * Button for Job Category search.
   * @param label
   * @returns
   */
  static JobCategory(localization: LocalizationData): IFilterMainButton {
    return FilterMainButton.Button({
      label: LocalizationService.t(localization, LocalizationPath.jobCategory),
      icon: CIconFactory.Icon({
        icon: 'briefcase',
        classes: 'color-green-pastel cursor',
        size: IconSize.medium
      }),
      classes: 'row-between align-center theme-shades-light1-color br-10 ph-10 pv-5 text-small',
      width: '',
      height: '34px',
      cyTag: 'job-category-btn'
    })
  }
  /**
   * Button for Location search.
   * @param label
   * @returns
   */
  static Location(localization: LocalizationData): IFilterMainButton {
    return FilterMainButton.Button({
      label: LocalizationService.t(localization, LocalizationPath.location),
      icon: CIconFactory.Icon({
        icon: 'map-location-dot',
        classes: 'color-blue-pastel cursor',
        size: IconSize.medium
      }),
      classes: 'row-between align-center theme-shades-light1-color br-10 ph-10 pv-5 text-small',
      width: '',
      height: '34px',
      cyTag: 'location-btn'
    })
  }
  /**
   * Button for Visa Type search.
   * @returns
   */
  static VisaType(localization: LocalizationData): IFilterMainButton {
    return FilterMainButton.Button({
      label: LocalizationService.t(localization, LocalizationPath.visaType),
      icon: CIconFactory.Icon({
        icon: 'id-card',
        classes: 'color-purple-pastel cursor',
        size: IconSize.medium
      }),
      classes: 'row-between align-center theme-shades-light1-color br-10 ph-10 pv-5 text-small',
      width: '',
      height: '34px',
      cyTag: 'visa-type-btn'
    })
  }
  /**
   * Same function as the VisaType method, but used for when the search is
   * meant to be used within a form, as an input.
   * @returns
   */
  static VisaTypeFormInput(localization: LocalizationData): IFilterMainButton {
    const helper = InputHelperFactory.ResidenceCardHelper(localization)
    if (helper.icon) helper.icon.classes = 'color-blue-dark1'
    return FilterMainButton.Button({
      label: LocalizationService.t<string>(localization, LocalizationPath.SelectVisaType),
      classes: 'theme-blue-dark1-white color-blue-dark1 h-50 small-screen',
      width: '',
      height: '',
      asterisk: true,
      helper,
      cyTag: 'visa-type-btn'
    })
  }
  /**
   * Button for Salary filter.
   * @returns
   */
  static SalaryRange(): IFilterMainButton {
    return FilterMainButton.Button({
      label: 'Salary Range',
      icon: CIconFactory.Icon({
        icon: 'yen-sign',
        classes: 'color-yellow-pastel cursor',
        size: IconSize.medium
      }),
      classes: 'row-between align-center theme-shades-light1-color br-10 ph-10 pv-5 text-small',
      width: '',
      height: '34px',
      cyTag: 'salary-range-btn'
    })
  }
  /**
   * Button for Visa Type search.
   * @param label
   * @returns
   */
  static OtherFilters(localization: LocalizationData): IFilterMainButton {
    return FilterMainButton.Button({
      label: LocalizationService.t(localization, LocalizationPath.otherFilter),
      icon: CIconFactory.Icon({
        icon: 'sliders',
        classes: 'color-shades-medium1 cursor',
        size: IconSize.medium
      }),
      classes: 'row-between align-center theme-shades-light1-color br-10 ph-10 pv-5 text-small',
      width: '',
      height: '34px',
      cyTag: 'other-filters-btn'
    })
  }
}
