import { IFilterableDataSetDictionary } from "../../../../../../../core/src/models/common/filter/i-filterable-data-set-dictionary"
import { LocalizationPath } from "../../../../../../../core/src/models/common/localization/constants/localization-path"
import { LocalizationData } from "../../../../../../../core/src/models/common/localization/localization-data"
import { FilterKey } from "../../../../../../../core/src/models/vacancy/enum/filter-key.enum"
import { LocalizationService } from "../../../../../../core/src"
import { FilterSearchHelpers } from "../enum/filter-search-helpers.enum"
import { FilterTypes } from "../enum/filter-types.enum"
import { PopoutClasses } from "../enum/popout-classes.enum"
import { PopoutPosition } from "../enum/popout-position.enum"
import { IFilterMultiOption } from "../interface/i-filter-multi-option"
import { IFilterSearch } from "../interface/i-filter-search"
import { FilterMainButton } from "./filter-main-button.factory"


export class FilterFactory {
  /**
   * Create a Search Filter.
   */
  static SearchFilter(props: IFilterSearch): IFilterSearch {
    return {
      id: props.id,
      name: props.name,
      button: props.button,
      treeData: props.treeData,
      filterType: props.filterType,
      popoutPosition: props.popoutPosition,
      popoutClasses: props.popoutClasses,
      showChildrenAndBreadcrumb: props.showChildrenAndBreadcrumb,
      doubleButtonStyle: props.doubleButtonStyle,
      placeholderSearchText: props.placeholderSearchText,
      placeholderSearchTextCategory: props.placeholderSearchTextCategory,
      placeholderSearchTextPath: props.placeholderSearchTextPath,
      helper: props.helper
    }
  }
  /**
   * Create a Multi Option / Others Filter.
   */
  static MultiOptionFilter(props: IFilterMultiOption): IFilterMultiOption {
    return {
      id: props.id,
      name: props.name,
      button: props.button,
      data: props.data,
      filterType: props.filterType,
      popoutPosition: props.popoutPosition
    }
  }
  /**
   * Create a Search Filter.
   */
  static VisaTypeSearchFilter(localization: LocalizationData, treeData: IFilterableDataSetDictionary): IFilterSearch {
    return FilterFactory.SearchFilter({
      id: FilterKey.visa,
      name: 'Visa Type',
      button: FilterMainButton.VisaType(localization),
      treeData: treeData,
      filterType: FilterTypes.FilterSearch,
      popoutPosition: PopoutPosition.Left,
      popoutClasses: PopoutClasses.FilterPopout,
      placeholderSearchText: LocalizationService.t(localization, LocalizationPath.visaTypePlaceholder),
      placeholderSearchTextPath: LocalizationPath.visaTypePlaceholder,
      helper: FilterSearchHelpers.VisaType
    })
  }

  /**
   * Create a Search Filter.
   */
  static VisaTypeSearchFilterFormInput(localization: LocalizationData, treeData: IFilterableDataSetDictionary): IFilterSearch {
    return FilterFactory.SearchFilter({
      id: FilterKey.visa,
      name: 'Visa Type',
      button: FilterMainButton.VisaTypeFormInput(localization),
      treeData: treeData,
      filterType: FilterTypes.FilterSearch,
      popoutPosition: PopoutPosition.Absolute,
      popoutClasses: PopoutClasses.FormPopout,
      placeholderSearchText: LocalizationService.t<string>(localization, LocalizationPath.visaTypePlaceholder),
      placeholderSearchTextPath: LocalizationPath.visaTypePlaceholder
    })
  }

  /**
   * Create a Search Filter.
   */
  static VisaTypeSearchFilterRibbon(localization: LocalizationData, treeData: IFilterableDataSetDictionary): IFilterSearch {
    return FilterFactory.SearchFilter({
      id: FilterKey.visa,
      name: 'Visa Type',
      button: FilterMainButton.VisaTypeFormInput(localization),
      treeData: treeData,
      filterType: FilterTypes.FilterSearch,
      popoutPosition: PopoutPosition.Fullscreen,
      popoutClasses: PopoutClasses.FormPopout,
      placeholderSearchText: LocalizationService.t<string>(localization, LocalizationPath.visaTypePlaceholder),
      placeholderSearchTextPath: LocalizationPath.visaTypePlaceholder
    })
  }

  /**
   * Create a Search Filter.
   */
  static JobCatergorySearchFilter(localization: LocalizationData, treeData: IFilterableDataSetDictionary): IFilterSearch {
    return FilterFactory.SearchFilter({
      id: FilterKey.category,
      name: 'Job Category',
      button: FilterMainButton.JobCategory(localization),
      treeData: treeData,
      filterType: FilterTypes.FilterSearch,
      popoutPosition: PopoutPosition.Center,
      popoutClasses: PopoutClasses.FilterPopout,
      doubleButtonStyle: true,
      placeholderSearchText: LocalizationService.t<string>(localization, LocalizationPath.jobCategoryPlaceholder),
      placeholderSearchTextPath: LocalizationPath.jobCategoryPlaceholder
    })
  }

  /**
   * Create a Search Filter.
   */
  static LocationSearchFilter(localization: LocalizationData, treeData: IFilterableDataSetDictionary): IFilterSearch {
    return FilterFactory.SearchFilter({
      id: FilterKey.location,
      name: 'Location',
      button: FilterMainButton.Location(localization),
      treeData: treeData,
      filterType: FilterTypes.FilterSearch,
      popoutPosition: PopoutPosition.Center,
      popoutClasses: PopoutClasses.FilterPopout,
      showChildrenAndBreadcrumb: true,
      doubleButtonStyle: true,
      placeholderSearchText: LocalizationService.t<string>(localization, LocalizationPath.locationPlaceholder),
      placeholderSearchTextPath: LocalizationPath.locationPlaceholder,
      helper: FilterSearchHelpers.MapSearch
    })
  }

  /**
   * Create a Search Filter.
   */
  static SalaryRangeSearchFilter(treeData: IFilterableDataSetDictionary): IFilterSearch {
    return FilterFactory.SearchFilter({
      id: 'salaryRange',
      name: 'Salary Range',
      button: FilterMainButton.SalaryRange(),
      treeData: treeData,
      filterType: FilterTypes.FilterSearch,
      popoutPosition: PopoutPosition.Right,
      popoutClasses: PopoutClasses.FilterPopout
    })
  }

  /**
   * Create a Search Filter.
   */
  static OtherSearchFilters(localization: LocalizationData): IFilterMultiOption {
    return FilterFactory.MultiOptionFilter({
      id: 'multiOption', // TODO: Change the id when we add more filters.
      name: 'Other Filters',
      button: FilterMainButton.OtherFilters(localization),
      filterType: FilterTypes.FilterMultiOption,
      popoutPosition: PopoutPosition.Right,
      popoutClasses: PopoutClasses.FilterPopout,
      data: {}
    })
  }
}
